import React from "react";
import "./admindashboard.scss";
import axios from "axios";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
const url = process.env.REACT_APP_HOST_ADDR;
const apikey = process.env.REACT_APP_APIKEY;
export const AdminDashBoard = () => {

  const checkTeam = async () =>{
    const resp = await axios.get(
      url + "/admin/calcteam",
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
        alert("Fetched...")
    }
  }
  return (
    <div className="admindashboard">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-4">
          <Link to="/admin/levelcheck">
            <Button label="Level Update" />
          </Link>
        </div>
        <div className="col-12 md:col-12 lg:col-4">
            <Button label="Calculate Team Business" onClick={()=>checkTeam()} />
        </div>
      </div>
    </div>
  );
};
